import Drawer, { DrawerProps } from '../Drawer';
import Box from '../Box';

interface AppBarDrawerProps extends DrawerProps {
  handleDrawerClose: () => void;
  letsConnectCard: React.ReactElement | null;
}

const AppBarDrawer: React.FC<AppBarDrawerProps> = ({
  children,
  letsConnectCard,
  handleDrawerClose,
  ...props
}) => {
  return (
    <Drawer {...props} onClose={handleDrawerClose}>
      <Box
        minWidth={360}
        height={1}
        pb={6}
        px={5}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        bgcolor="background.secondaryMain"
      >
        <nav className="mt-[10dvh] h-full w-full overflow-x-hidden overflow-y-scroll">
          {children}
        </nav>
        <section className="mx-auto text-center">{letsConnectCard}</section>
      </Box>
    </Drawer>
  );
};

export default AppBarDrawer;
