import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';
import { ITheme } from '../../../common/theme';

interface StyleProps {
  lightTheme?: boolean;
  popoverOpenState?: boolean;
}

export default makeStyles((theme: ITheme) => ({
  slogan: ({ lightTheme }: StyleProps) => {
    const color = lightTheme ? 'black' : 'white';

    return {
      color: alpha(theme.palette.common[color], 0.5),
    };
  },
  menuButton: () => {
    return {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    };
  },
  link: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: 'relative',
    top: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    borderRadius: 5,
    pointerEvents: 'auto',
    boxShadow: `0px 1px 5px 1px rgb(158 158 158 / 21%)`,
  },
  menuList: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  menuListItem: {
    fontSize: '20px',
    lineHeight: '24px',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    color: theme.palette.grey[900],
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
