import { Content } from '@prismicio/client';
import { socialMediaIcons, ArcFooterLogo } from '@/components/Unknown/Icons';
import Container from '@/components/Unknown/Container';
import { PrismicNextLink } from '@prismicio/next';

interface FooterProps {
  footerData: Content.FooterDocument;
}

const socialMediaIconsMapping = {
  Facebook: socialMediaIcons.Facebook,
  YouTube: socialMediaIcons.YouTube,
  Instagram: socialMediaIcons.Instagram,
  LinkedIn: socialMediaIcons.Linkedin,
};
const Footer: React.FC<FooterProps> = ({ footerData }) => {
  const data = footerData.data;
  return (
    <footer className="bg-paper ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]">
      <Container>
        <div className="sm: flex flex-wrap justify-between gap-x-6 gap-y-8 text-black sm:gap-y-16">
          <section className="w-full text-center o-4 lg:w-auto lg:text-left">
            <ArcFooterLogo className="mx-auto w-[149px] text-brand-color-main lg:mx-0" />
            <div className="text-custom-gray-body o-2">
              <span className="block text-sm">
                &#169;
                {` ${data.year_established} - ${new Date().getFullYear()}`}
              </span>
              <span className="block text-sm transition-colors [&_a]:text-custom-gray-body [&_a]:underline-offset-4 hover:[&_a]:underline">
                <PrismicNextLink field={data.privacy_link}>
                  Privacy
                </PrismicNextLink>
                &#8196;&mdash;&#8196;
                <PrismicNextLink field={data.terms_link}>Terms</PrismicNextLink>
              </span>
            </div>
            <ul className="flex place-content-center gap-4">
              {data.socials.map((item, index) => (
                <li key={index}>
                  <PrismicNextLink
                    field={item.link}
                    className="text-night-blue transition-colors hover:text-brand-color-main"
                  >
                    {item.icon && socialMediaIconsMapping[item.icon]}
                  </PrismicNextLink>
                </li>
              ))}
            </ul>
          </section>
          {data.slices.map((slice, index) => (
            <section
              key={index}
              className="w-full o-2 sm:w-[calc(50%-(1.5rem/2))] md:w-auto"
            >
              <p className="text-arc-base font-bold xl:text-arc-lg">
                {slice.primary.heading}
              </p>
              <ul className="flex flex-col gap-3">
                {slice.primary.links.map((item, innerIndex) => (
                  <li key={innerIndex}>
                    <PrismicNextLink
                      field={item.link}
                      className="text-arc-base text-night-blue underline-offset-4 hover:underline xl:text-arc-lg"
                    >
                      {item.name}
                    </PrismicNextLink>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
