import DrawerBase, { DrawerProps } from '@material-ui/core/Drawer';

export type { DrawerProps };

const Drawer: React.FC<DrawerProps> = ({ children, ...props }) => {
  return (
    <DrawerBase elevation={0} anchor="right" {...props}>
      {children}
    </DrawerBase>
  );
};

export default Drawer;
