import React, { forwardRef } from 'react';
import ButtonBase, {
  ButtonProps as ButtonBaseProps,
} from '@material-ui/core/Button';
import useStyles from './useStyles';

interface ButtonProps extends ButtonBaseProps {
  borderRadius?: 'squared' | 'rounded' | 'circular';
  component?: string | React.FC;
  target?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ borderRadius = 'rounded', children, ...props }, ref) => {
    const classes = useStyles();

    return (
      <ButtonBase ref={ref} className={classes[borderRadius]} {...props}>
        {children}
      </ButtonBase>
    );
  },
);

export default Button;
