import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  rounded: {
    borderRadius: 5,
  },
  squared: {
    borderRadius: 0,
  },
  circular: {
    borderRadius: 30,
  },
});
