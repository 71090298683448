import { makeStyles } from '@material-ui/styles';
import { ITheme } from '../../../common/theme';

interface StyleProps {
  lightTheme?: boolean;
  popoverOpenState?: boolean;
}

export default makeStyles((theme: ITheme) => ({
  menuIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuButton: ({ popoverOpenState }: StyleProps) => {
    const buttonColor = popoverOpenState
      ? theme.palette.secondary.main
      : theme.palette.common.white;

    return {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      color: buttonColor,
      fontSize: 20,
      lineHeight: '21px',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    };
  },
  buttonIcon: ({ popoverOpenState }) => {
    const rotateDegree = popoverOpenState ? '180deg' : '0deg';

    return {
      position: 'absolute',
      top: '50%',
      right: -10,
      transform: `translateY(-30%) rotateX(${rotateDegree})`,
    };
  },
  link: {
    display: 'block',
    paddingTop: theme.spacing(4.5),
    paddingBottom: theme.spacing(4.5),
  },
  popoverContent: {
    backgroundColor: 'transparent',
  },
  menuList: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  menuListItem: {
    minHeight: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  topLine: {
    transition: 'transform 0.3s ease-in-out',
  },
  bottomLine: {
    transition: 'transform 0.3s ease-in-out',
  },
  topLineClosed: {
    transform: 'translateY(0)',
  },
  topLineOpened: {
    transform: 'translateY(6px)',
  },
  bottomLineClosed: {
    transform: 'translateY(0)',
  },
  bottomLineOpened: {
    transform: 'translateY(-6px)',
  },
}));
