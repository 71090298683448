import { useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';
import Box from '../Box';

interface AppBarAnimatedBackgroundProps {
  scrollY: number;
  transparent: boolean;
}

const AppBarAnimatedBackground: React.FC<AppBarAnimatedBackgroundProps> = ({
  scrollY,
  transparent,
}) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    if (scrollY > 150) {
      setFadeIn(true);
    } else {
      setFadeIn(false);
    }
  }, [scrollY]);

  return (
    <Fade in={fadeIn || !transparent} timeout={500}>
      <Box
        position="absolute"
        zIndex={-1}
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgcolor="background.primaryMain"
      />
    </Fade>
  );
};

export default AppBarAnimatedBackground;
